import Vue from "vue";
import Vuetify from "vuetify";
import "vuetify/dist/vuetify.min.css";
import pt from "vuetify/lib/locale/pt";

Vue.use(Vuetify);

export default new Vuetify({
  theme: {
    options: {
      customProperties: true,
    },
    themes: {
      light: {
        primary: "#091242",
        secondary: "#469CEF",
        error: "#d21828",
        $info: "#3786c7",
        success: "#15a74a",
        warning: "#ffe883",
        neutral: "#ffffff",
        background: "#f4f7fe",
        background1: "#f6f6f6",
        background2: "#e9ecef",
        background3: "#ced3da",
      },
    },
  },
  lang: {
    locales: { pt },
    current: "pt",
  },
});
